import React from "react";

import { useLazyFeatureFlag } from "@speedwaymotors/clutch/Hooks/useFeatureFlag/useFeatureFlag";

import HeroSectionContent from "./HeroSectionContent";

const HeroSectionWrapper = ({ audience }) => {
  const heroFeatureFlag = useLazyFeatureFlag("HomePage_Hero_Section");

  console.debug("HHFF: ", heroFeatureFlag);

  return (
    heroFeatureFlag === false && <HeroSectionContent audience={audience} />
  );
};

export default HeroSectionWrapper;
