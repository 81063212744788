import React from "react";
import { useInView } from "react-intersection-observer";

import { useLazyFeatureFlag } from "@speedwaymotors/clutch/Hooks/useFeatureFlag/useFeatureFlag";
import Panel from "Clutch/Atoms/Panel";
import Typography from "Clutch/Atoms/Typography";
import { IsUserBot } from "Clutch/Utilities/CookieUtilties/UserCookieUtilities";
import { DecodeEdgeFeatureFlags } from "Clutch/Utilities/EdgeFeatureFlags";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";
import Ga4ContentGroup from "Clutch/UtilityComponents/Ga4ContentGroup/Ga4ContentGroup";
import GraphQLRequest from "Utilities/GraphQLClient/GraphQLClient";
import dynamic from "next/dynamic";
import Script from "next/script";

import Default from "../../../../../../components/audience-pages/Default/Default";
import Employee from "../../../../../../components/audience-pages/Employee/Employee";
import Race from "../../../../../../components/audience-pages/Race/Race";
import Street from "../../../../../../components/audience-pages/Street/Street";
import HeroSectionContent from "../../../../../../components/hero-section/HeroSectionContent";
import HeroSectionWrapper from "../../../../../../components/hero-section/HeroSectionWrapper";
import RaceTypeLinkRack from "../../../../../../components/marketLinks/raceTypeLinks";
import BrandsSection from "../../../../../../components/modular-sections/brands-section/BrandsSection";
import EmailSignUpSection from "../../../../../../components/modular-sections/email-signup-section/EmailSection";
import FlatCategorySection from "../../../../../../components/modular-sections/flat-category-section/FlatCategorySection";
import MrpEventSection from "../../../../../../components/modular-sections/mrp-event-section/MrpEventSection";
import ShopLinkSection from "../../../../../../components/modular-sections/shop-link-section/ShopLinkSection";
import ValueAdds from "../../../../../../components/modular-sections/value-adds/ValueAdds";
import PersonalizationInitialSkeleton from "../../../../../../components/personalization-initial-skeleton/PersonalizationInitialSkeleton";
import PersonalizedContentSection from "../../../../../../components/personalizedContent/PersonalizedContentSection";
import Section from "../../../../../../components/section/Section";
import {
  catalogCallout,
  collectionCallout,
  dealsCallout,
  engineCallout,
  giftcardCallout,
  raceCallout,
  rewardsSignUpCallout,
  shockCallout,
  streetCallout,
  trendingCallout,
} from "../../../../../../constants/calloutConstants";
import modularSectionTypes from "../../../../../../constants/modularSectionTypes";
import { homePageRevalTime } from "../../../../../../constants/revalidationTimes";
import userTypes from "../../../../../../constants/userTypes";
import getHomePageQuery from "../../../../../../graphql/getHomePage";
import { HomePageProvider } from "../../../../../../providers/HomePageProvider";
import getAudienceEnumValue from "../../../../../../utils/audienceEnumUtils";
import { isCawWeek } from "../../../../../../utils/cawUtils";

dynamic(() =>
  import("../../../../../../chunks/chunk1").then((module) => module.HeroSection)
);
const HeadContent = dynamic(() =>
  import("../../../../../../chunks/chunk1").then((module) => module.HeadContent)
);
const AudienceTag = dynamic(() =>
  import("../../../../../../chunks/chunk1").then((module) => module.AudienceTag)
);
const Maker = dynamic(() =>
  import("../../../../../../chunks/chunk1").then((module) => module.Maker)
);
const RecBlock = dynamic(() =>
  import("../../../../../../chunks/chunk1").then((module) => module.RecBlock)
);
const UgcBlock = dynamic(() =>
  import("../../../../../../chunks/chunk1").then((module) => module.UgcBlock)
);
const CategorySection = dynamic(() =>
  import("../../../../../../chunks/chunk1").then(
    (module) => module.CategorySection
  )
);
const CalloutSection = dynamic(() =>
  import("../../../../../../chunks/chunk1").then(
    (module) => module.CalloutSection
  )
);
const MasonrySection = dynamic(() =>
  import("../../../../../../chunks/chunk1").then(
    (module) => module.MasonrySection
  )
);

const ToolboxSection = dynamic(() =>
  import("../../../../../../chunks/chunk_deferred").then(
    (module) => module.ToolboxSection
  )
);

const audiencePageLookup = {
  race: Race,
  street: Street,
  employee: Employee,
  default: Default,
};

export async function getStaticPaths() {
  return {
    paths: [
      {
        params: {
          audience: "race",
          usertype: "new",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "true",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "street",
          usertype: "new",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "true",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "employee",
          usertype: "new",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "true",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "default",
          usertype: "new",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "true",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "race",
          usertype: "returning",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "true",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "street",
          usertype: "returning",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "true",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "employee",
          usertype: "returning",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "true",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "default",
          usertype: "returning",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "true",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "race",
          usertype: "new",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "false",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "street",
          usertype: "new",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "false",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "employee",
          usertype: "new",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "false",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "default",
          usertype: "new",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "false",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "race",
          usertype: "returning",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "false",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "street",
          usertype: "returning",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "false",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "employee",
          usertype: "returning",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "false",
          showPwaBackButton: false,
        },
      },
      {
        params: {
          audience: "default",
          usertype: "returning",
          edgeFlags: "__EDGEFLAGS__",
          hasCustomerProjects: "false",
          showPwaBackButton: false,
        },
      },
    ],
    fallback: "blocking",
  };
}

export async function getStaticProps({ params }) {
  const { audience, usertype, hasCustomerProjects } = params;
  try {
    const contentResponse = await GraphQLRequest(
      process.env.CONTENT_WEBSERVICE_URL,
      getHomePageQuery,
      { audience: getAudienceEnumValue(audience) }
    );
    const homePageData = contentResponse.homePage;

    const edgeFlags = DecodeEdgeFeatureFlags(params.edgeFlags);

    return {
      props: {
        initialState: {
          // modularSections: orderedModularSections,
          ...homePageData,
          canonicalUrl: process.env.CANONICAL_URL,
          audience,
          hasCustomerProjects: hasCustomerProjects === "true",
        },
        showPwaBackButton: false,
        audience,
        usertype,
        hasCustomerProjects,
        ...edgeFlags,
      },
      revalidate: homePageRevalTime,
    };
  } catch (err) {
    console.log(err);
    return { props: { audience, usertype }, revalidate: homePageRevalTime };
  }
}

const Audience = ({
  audience,
  usertype,
  initialState,
  freeShippingThreshold,
  hasCustomerProjects,
}) => {
  const { modularSections } = initialState;
  const AudiencePage = audiencePageLookup[audience.toLowerCase()];
  const isFirstTimeUser =
    usertype.toLowerCase() === userTypes.new.toLowerCase();

  console.debug("HCP: ", hasCustomerProjects);

  const isCAW = isCawWeek();

  const makerSection = modularSections.find((x) =>
    [
      modularSectionTypes.maker,
      modularSectionTypes.makerBoosted,
      modularSectionTypes.makerBurried,
    ].includes(x.type)
  );

  const homePageStreetRaceVsPersonalized = useLazyFeatureFlag(
    "HomePage_StreetRace_vs_Personalized"
  );

  return (
    <>
      <HeadContent
        audience={audience}
        canonicalUrl={initialState.canonicalUrl}
        preloadMaker={!!makerSection}
      />
      <HomePageProvider
        intialState={initialState}
        freeShippingThreshold={freeShippingThreshold}
      >
        <Ga4ContentGroup contentGroup={"new_homepage"} />
        <Panel
          role={"main"}
          style={{ overflowX: "hidden" }}
          id={"New-HomePage"}
        >
          <Script strategy="beforeInteractive" id="maker-audience-script">
            {`
              window.audience = '${audience.toLowerCase()}';
              window.makerSchedule = '${makerSection?.makerIndex || ""}';
            `}
          </Script>
          {hasCustomerProjects === "false" && (
            <HeroSectionContent audience={audience} />
          )}
          {hasCustomerProjects === "true" && (
            <HeroSectionWrapper audience={audience} />
          )}
          {AudiencePage && <AudienceTag audience={audience} />}
          {modularSections.map((modularSection, i) => {
            const type = modularSection.type;
            let ModularSectionComponent = null;
            let sectionProps = null;
            switch (type) {
              case modularSectionTypes.maker:
              case modularSectionTypes.makerBoosted:
              case modularSectionTypes.makerBurried:
                return <Maker key={`modular-section-${i}`} />;
              case modularSectionTypes.ugc_black:
                sectionProps = {
                  title: "The Speedway Motors Community",
                  subtitle: (
                    <>
                      <Typography
                        size={1}
                        font={"regular"}
                        inline
                        tone={"contrast"}
                      >
                        Shop community projects and share your own hard work.{" "}
                      </Typography>
                      <Typography
                        size={1}
                        font={"bold"}
                        inline
                        tone={"contrast"}
                      >
                        {" "}
                        #builtwithspeedwaymotors
                      </Typography>
                    </>
                  ),
                  inverseTheme: true,
                  extendGutters: true,
                  shrinkTitleOnMobile: true,
                  dataTestId: "ugc_section",
                };
                ModularSectionComponent = (
                  <UgcBlock content={modularSection.content} />
                );
                break;
              case modularSectionTypes.ugc_white:
                sectionProps = {
                  title: "The Speedway Motors Community",
                  subtitle: (
                    <>
                      <Typography
                        size={1}
                        font={"regular"}
                        inline
                        tone={"contrast"}
                      >
                        Shop community projects and share your own hard work.{" "}
                      </Typography>
                      <Typography
                        size={1}
                        font={"bold"}
                        inline
                        tone={"contrast"}
                      >
                        {" "}
                        #builtwithspeedwaymotors
                      </Typography>
                    </>
                  ),
                  inverseTheme: false,
                  extendGutters: true,
                  shrinkTitleOnMobile: true,
                  dataTestId: "ugc_section",
                };
                ModularSectionComponent = (
                  <UgcBlock content={modularSection.content} />
                );
                break;
              case modularSectionTypes.emailNewsletter_black:
                sectionProps = {
                  noVerticalSpacing: true,
                  extendGutters: true,
                  dataTestId: "email_section",
                  suppressImpressionBoundary: true,
                };
                ModularSectionComponent = (
                  <EmailSignUpSection
                    key={type}
                    dataTestId={sectionProps.dataTestId}
                    type={"black"}
                    audience={audience}
                  />
                );
                break;
              case modularSectionTypes.emailNewsletter_purple:
                sectionProps = {
                  noVerticalSpacing: true,
                  extendGutters: true,
                  dataTestId: "email_section",
                  suppressImpressionBoundary: true,
                };
                ModularSectionComponent = (
                  <EmailSignUpSection
                    key={type}
                    dataTestId={sectionProps.dataTestId}
                    type={"purple"}
                    audience={audience}
                  />
                );
                break;
              case modularSectionTypes.recProductBlock:
                sectionProps = {
                  dataTestId: "rec_section",
                };
                ModularSectionComponent = (
                  <RecBlock data={modularSection.testData} />
                );
                break;
              case modularSectionTypes.raceTypeLinks:
                sectionProps = {
                  title: "What do you race?",
                  dataTestId: "race_type_link_section",
                };
                ModularSectionComponent = (
                  <RaceTypeLinkRack links={modularSection.marketLinks} />
                );
                break;
              case modularSectionTypes.mrpEvents:
                sectionProps = {
                  dataTestId: "mrp_section",
                };
                ModularSectionComponent = <MrpEventSection />;
                break;
              case modularSectionTypes.toolbox:
                sectionProps = {
                  extendGutters: true,
                  dataTestId: "toolbox_section",
                };
                ModularSectionComponent = (
                  <ToolboxSection
                    audience={audience}
                    recommendations={modularSection.recommendations}
                  />
                );
                break;
              case modularSectionTypes.categories:
                sectionProps = {
                  title: "Performance Parts Categories",
                  dataTestId: "category_section",
                  shrinkTitleOnMobile: true,
                };
                ModularSectionComponent = (
                  <CategorySection categories={modularSection.categories} />
                );
                break;
              case modularSectionTypes.flatCategories:
                sectionProps = {
                  title: "Shop Popular Categories",
                  dataTestId: "flat_category_section",
                  shrinkTitleOnMobile: true,
                };
                ModularSectionComponent = (
                  <FlatCategorySection categories={modularSection.categories} />
                );
                break;
              case modularSectionTypes.brands:
                sectionProps = {
                  title: "Best Aftermarket Auto Parts Brands",
                  subTitle: "High performance featured at Speedway Motors",
                  dataTestId: "brand_section",
                  shrinkTitleOnMobile: true,
                };
                ModularSectionComponent = (
                  <BrandsSection brands={modularSection.brands} />
                );
                break;
              case modularSectionTypes.streetRaceCallouts:
                if (
                  homePageStreetRaceVsPersonalized &&
                  hasCustomerProjects === "true"
                ) {
                  break;
                }

                sectionProps = {
                  title: "Shop Aftermarket Performance Car Parts",
                  useH1Title: true,
                  dataTestId: "street_race_section",
                  shrinkTitleOnMobile: true,
                };

                ModularSectionComponent = (
                  <CalloutSection
                    leftCallout={streetCallout}
                    rightCallout={raceCallout}
                  />
                );

                break;
              case modularSectionTypes.engineShockCallouts:
                sectionProps = {
                  title: "Engine Machining & Shock Rebuilding",
                  subtitle:
                    "Fast turnarounds on shock rebuilds and dyno tests.",
                  dataTestId: "engine_shock_section",
                  shrinkTitleOnMobile: true,
                };
                ModularSectionComponent = (
                  <CalloutSection
                    leftCallout={engineCallout}
                    rightCallout={shockCallout}
                  />
                );
                break;
              case modularSectionTypes.shopLinks:
                sectionProps = {
                  title: "Shop Custom Car Parts",
                  dataTestId: "shop_links_section",
                  shrinkTitleOnMobile: true,
                };
                ModularSectionComponent = (
                  <ShopLinkSection audience={audience} />
                );
                break;
              case modularSectionTypes.valueAdds:
                sectionProps = {
                  noVerticalSpacing: true,
                  extendGutters: true,
                  dataTestId: "value_adds_section",
                };
                ModularSectionComponent = <ValueAdds />;
                break;
              case modularSectionTypes.catalogGiftcardCallouts:
                sectionProps = {
                  title: "Bring the Gearhead Love Home",
                  dataTestId: "catalog_giftcard_section",
                  shrinkTitleOnMobile: true,
                };
                ModularSectionComponent = (
                  <CalloutSection
                    leftCallout={catalogCallout}
                    rightCallout={giftcardCallout}
                  />
                );
                break;
              case modularSectionTypes.masonry:
                sectionProps = {
                  title: "",
                  dataTestId: "masonry_section",
                };
                ModularSectionComponent = (
                  <MasonrySection
                    leftCallout={collectionCallout}
                    topRightCallout={
                      isCAW ? rewardsSignUpCallout : trendingCallout
                    }
                    bottomRightCallout={dealsCallout}
                  />
                );
                break;
              case modularSectionTypes.personalized:
                if ((IsUserBot() || isFirstTimeUser) && !isCAW) {
                  ModularSectionComponent = false;
                  break;
                }
                sectionProps = { layer: 1, dataTestId: "personalized_section" };
                ModularSectionComponent = (
                  <DeferWrapper skeleton={<PersonalizationInitialSkeleton />}>
                    <PersonalizedContentSection
                      subSections={modularSection.subSections}
                      audience={audience}
                    />
                  </DeferWrapper>
                );
                break;

              default:
                break;
            }
            if (!ModularSectionComponent) {
              return false;
            }
            return (
              <ErrorBoundary key={type}>
                <Section key={type} {...sectionProps} audience={audience}>
                  {ModularSectionComponent}
                </Section>
              </ErrorBoundary>
            );
          })}
          <Section dataTestId={"clarityContent"} audience={audience}>
            <div id={"clarityContent"}></div>
          </Section>
          {AudiencePage && <AudiencePage />}
        </Panel>
      </HomePageProvider>
    </>
  );
};

const DeferWrapper = ({ children, skeleton }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: "200px 0px",
  });
  return (
    <>
      <span ref={ref}></span>
      {inView ? children : skeleton}
    </>
  );
};

export default Audience;
