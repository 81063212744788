import React from "react";

import { FitmentStateProvider } from "../../providers/FitmentStateProvider";
import HeroSection from "./HeroSection";
import styles from "./HeroSection.module.scss";

const HeroSectionContent = ({ audience }) => (
  <>
    <FitmentStateProvider>
      <HeroSection audience={audience.toLowerCase()} showGradient={false} />
    </FitmentStateProvider>
    <div className={styles.spacer}></div>
  </>
);

export default HeroSectionContent;
